import React, { useEffect } from "react";
import { Container, Box } from "@mui/system";
import { Typography } from "@mui/material";
import ImageBundle from "./ImageBundle";
import ScheduleEstimate from "./ScheduleEstimate";
import Image1 from "../static/_pavers_best_large/pavers00001.jpeg";
import Image2 from "../static/_pavers_best_large/pavers00002.jpeg";
import Image3 from "../static/_pavers_best_large/pavers00003.jpeg";
import Image4 from "../static/_pavers_best_large/pavers00004.jpeg";
import Image5 from "../static/_pavers_best_large/pavers00005.jpeg";
import Image6 from "../static/_pavers_best_large/pavers00006.jpeg";
import Image7 from "../static/_pavers_best_large/pavers00007.jpeg";
import Image8 from "../static/_pavers_best_large/pavers00008.jpeg";
import Image9 from "../static/_pavers_best_large/pavers00009.jpeg";
import Image10 from "../static/_pavers_best_large/pavers00010.jpeg";
import Image11 from "../static/_pavers_best_large/pavers00011.jpeg";
import Image12 from "../static/_pavers_best_large/pavers00012.jpeg";
import Image13 from "../static/_pavers_best_large/pavers00013.jpeg";
import Image14 from "../static/_pavers_best_large/pavers00014.jpeg";
import Image15 from "../static/_pavers_best_large/pavers00015.jpeg";
import Image16 from "../static/_pavers_best_large/pavers00016.jpeg";
import Image17 from "../static/_pavers_best_large/pavers00017.jpeg";
import Image18 from "../static/_pavers_best_large/pavers00018.jpeg";
import Image19 from "../static/_pavers_best_large/pavers00019.jpeg";
import Image20 from "../static/_pavers_best_large/pavers00020.jpeg";
import Image21 from "../static/_concrete_large/concrete_lg_00001.jpeg";
import Image22 from "../static/_concrete_large/concrete_lg_00002.jpeg";
import Image23 from "../static/_concrete_large/concrete_lg_00003.jpeg";
import Image24 from "../static/_concrete_large/concrete_lg_00004.jpeg";
import Image25 from "../static/_concrete_large/concrete_lg_00005.jpeg";
import Image26 from "../static/_concrete_large/concrete_lg_00006.jpeg";
import Image27 from "../static/_concrete_large/concrete_lg_00007.jpeg";
import Image28 from "../static/_concrete_large/concrete_lg_00008.jpeg";
import Image29 from "../static/_concrete_large/concrete_lg_00009.jpeg";
import Image30 from "../static/_concrete_large/concrete_lg_00010.jpeg";
import Image31 from "../static/_concrete_large/concrete_lg_00011.jpeg";
import Image32 from "../static/_concrete_large/concrete_lg_00012.jpeg";
import Image33 from "../static/_concrete_large/concrete_lg_00013.jpeg";
import Image63 from "../static/_concrete_large/concrete_lg_00014.jpeg";
import Image34 from "../static/_steps_large/steps_lg00001.jpeg";
import Image35 from "../static/_steps_large/steps_lg00002.jpeg";
import Image36 from "../static/_steps_large/steps_lg00003.jpeg";
import Image37 from "../static/_steps_large/steps_lg00004.jpeg";
import Image38 from "../static/_steps_large/steps_lg00005.jpeg";
import Image39 from "../static/_steps_large/steps_lg00006.jpeg";
import Image40 from "../static/_steps_large/steps_lg00007.jpeg";
import Image41 from "../static/_steps_large/steps_lg00008.jpeg";
import Image42 from "../static/_steps_large/steps_lg00009.jpeg";
import Image43 from "../static/_steps_large/steps_lg00010.jpeg";
import Image44 from "../static/_steps_large/steps_lg00011.jpeg";
import Image45 from "../static/_steps_large/steps_lg00012.jpeg";
import Image54 from "../static/_steps_large/steps_lg00013.jpeg";
import Image55 from "../static/_steps_large/steps_lg00014.jpeg";
import Image56 from "../static/_steps_large/steps_lg00015.jpeg";
import Image57 from "../static/_steps_large/steps_lg00016.jpeg";
import Image58 from "../static/_steps_large/steps_lg00017.jpeg";
import Image59 from "../static/_steps_large/steps_lg00018.jpeg";
import Image60 from "../static/_steps_large/steps_lg00019.jpeg";
import Image61 from "../static/_steps_large/steps_lg00020.jpeg";
import Image62 from "../static/_steps_large/steps_lg00021.jpeg";
import Image46 from "../static/_large_retaining/retaining_lg00001.jpeg";
import Image47 from "../static/_large_retaining/retaining_lg00002.jpeg";
import Image48 from "../static/_large_retaining/retaining_lg00003.jpeg";
import Image49 from "../static/_large_retaining/retaining_lg00004.jpeg";
import Image50 from "../static/_large_retaining/retaining_lg00005.jpeg";
import Image51 from "../static/_large_retaining/retaininig_lg00006.jpeg";
import Image52 from "../static/_large_retaining/retaininig_lg00007.jpeg";
import Image53 from "../static/_large_retaining/retaininig_lg00008.jpeg";
const data = [
  { url: Image1 },
  { url: Image20 },
  { url: Image18 },
  { url: Image4 },
  { url: Image5 },
  { url: Image6 },
  { url: Image7 },
  { url: Image8 },
  { url: Image9 },
  { url: Image10 },
  { url: Image19 },
  { url: Image12 },
  { url: Image13 },
  { url: Image14 },
  { url: Image15 },
  { url: Image16 },
  { url: Image17 },
  { url: Image3 },
  { url: Image11 },
  { url: Image2 }
];
const data2 = [
  { url: Image21 },
  { url: Image28 },
  { url: Image23 },
  { url: Image24 },
  { url: Image25 },
  { url: Image26 },
  { url: Image27 },
  { url: Image22 },
  { url: Image29 },
  { url: Image30 },
  { url: Image31 },
  { url: Image32 },
  { url: Image63 }
];
const data3 = [
  { url: Image34 },
  { url: Image42 },
  { url: Image56 },
  { url: Image43 },
  { url: Image54 },
  { url: Image55 },
  { url: Image55 },
  { url: Image36 },
  { url: Image57 },
  { url: Image58 },
  { url: Image59 },
  { url: Image60 },
  { url: Image61 },
  { url: Image62 },
  { url: Image38 },
  { url: Image39 },
  { url: Image40 },
  { url: Image41 },
  { url: Image35 },
  { url: Image37 },
  { url: Image44 },
  { url: Image45 }
];
const data4 = [
  { url: Image46 },
  { url: Image47 },
  { url: Image51 },
  { url: Image52 },
  { url: Image53 },
  { url: Image50 },
  { url: Image49 },
  { url: Image48 }
];
export default function Portfolio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: 2,
        paddingBottom: 2
      }}
    >
      <Box
        className="content"
        sx={{
          margin: "0 auto",
          paddingTop: 2
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: 2, textAlign: "center" }}
          gutterBottom
        >
          Our Portfolio
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "center" }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;Take a look at some of our completed projects
          featuring pavers installation, concrete masonry, steps and retaining
          walls. We pride ourselves in delivering high quality workmanship with
          attention to detail and customer satisfaction.
        </Typography>
      </Box>
      <Typography
        variant="h5"
        sx={{
          marginTop: 4,
          fontWeight: "bold",
          marginBottom: 3,
          textAlign: "center"
        }}
      >
        Pavers
      </Typography>
      <ImageBundle data={data}></ImageBundle>
      <Typography
        variant="h5"
        sx={{
          marginTop: 4,
          fontWeight: "bold",
          marginBottom: 3,
          textAlign: "center"
        }}
      >
        Concrete Work
      </Typography>
      <ImageBundle data={data2}></ImageBundle>
      <Typography
        variant="h5"
        sx={{
          marginTop: 4,
          fontWeight: "bold",
          marginBottom: 3,
          textAlign: "center"
        }}
      >
        Steps
      </Typography>
      <ImageBundle data={data3}></ImageBundle>
      <Typography
        variant="h5"
        sx={{
          marginTop: 4,
          fontWeight: "bold",
          marginBottom: 3,
          textAlign: "center"
        }}
      >
        Retaining Walls
      </Typography>
      <ImageBundle data={data4}></ImageBundle>
      <ScheduleEstimate />
    </Container>
  );
}
