import React from "react";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BoxWithText from "./BoxWithText";
import SeeMore from "./SeeMore";
import Image1 from "../static/_steps_large/steps_lg00015.jpeg";
import Image2 from "../static/_pavers_best_large/pavers00001.jpeg";
import Image3 from "../static/_concrete_large/concrete_lg_00008.jpeg";
import Image4 from "../static/_large_retaining/retaininig_lg00006.jpeg";

const mainText1 = [
  "    Polania Construction is the leading expert in brick step construction. Our skilled masons have been providing top-quality brick step services for over 30 years, and have a rich history of delivering only the best work to our customers. We understand the importance of enhancing the existing architecture of your home or business, and utilize innovative and specialized techniques to ensure that our brick steps will meet and exceed your expectations. With a wide variety of brick types and custom mortars, we guarantee that your steps will be unique and stunning. Our dedication to customer satisfaction is unparalleled, and we always aim to get the job done right the first time. Trust Polonia Construction for all of your brick step needs."
];
const mainText2 = [
  "    Polania Construction is the premier choice for all your paver needs. Our experienced installers deliver quality and craftsmanship in every project. We offer a wide variety of paver types to ensure that your patio, sidewalk, or driveway will be one-of-a-kind and enhance the existing architecture of your home or business. Our commitment to customer satisfaction is unparalleled, and we always aim to get the job done right the first time. Whether you are looking for a new paver patio, driveway, or sidewalk, Polania Construction is there to help."
];
const mainText3 = [
  "    Polania Construction provides highest quality concrete work. We offer both residential and commercial concrete services, and understand the importance of customer satisfaction. Whether you are looking for concrete sidewalks, driveways, or any other type of concrete work, we guarantee that your project will be handled with care and precision. Our dedication to customer care sets us apart from the competition."
];
const mainText4 = [
  "    Polania Construction is the trusted choice for all your retaining wall needs, delivering both strength and aesthetic appeal to your landscape. We offer a wide variety of materials and techniques, ensuring that your retaining wall will be both functional and beautiful. Our commitment to quality is unmatched. Whether you need a retaining wall for practical or aesthetic purposes we got your back! Trust us to exceed your expectations with our exceptional retaining wall services."
];
const imageArr = [Image1];
const imageArr2 = [Image2];
const imageArr3 = [Image3];
const imageArr4 = [Image4];
const theme = createTheme(theme => ({
  typography: {
    allVariants: {
      textTransform: "none",
      fontFamily: "roboto"
    }
  },
  mode: "dark",
  primary: {
    main: "#5893df"
  },
  secondary: {
    main: "#2ec5d3"
  },
  background: {
    default: "#192231",
    paper: "#24344d"
  }
}));
export default function Services() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 2
        }}
      >
        <Box
          className="content"
          sx={{
            margin: "0 auto",
            paddingTop: 2
          }}
        >
          <Typography
            variant="h4"
            sx={{ marginBottom: 2, textAlign: "center" }}
            gutterBottom
          >
            Our Services
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;We specialize in concrete and stone masonary
            for residential and commercial complexes. With years of experience,
            we pride ourselves in providing high-quality work and excellent
            customer service. Our team of experts works closely with each client
            to ensure their vision is brought to life. Contact us today to learn
            more about our services.
          </Typography>
        </Box>
      </Container>
      <BoxWithText
        imageArr={imageArr}
        textArr={mainText1}
        titleArr={["Steps"]}
        opposite={0}
      />
      <BoxWithText
        imageArr={imageArr2}
        textArr={mainText2}
        titleArr={["Pavers"]}
        opposite={1}
      />
      <BoxWithText
        imageArr={imageArr3}
        textArr={mainText3}
        titleArr={["Concrete Work"]}
        opposite={0}
      />
      <BoxWithText
        imageArr={imageArr4}
        textArr={mainText4}
        titleArr={["Retaining Walls"]}
        opposite={1}
      />
      <SeeMore></SeeMore>
    </ThemeProvider>
  );
}
