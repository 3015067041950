import "./App.css";
import MenuBar from "./Components/MenuBar";
import "@fontsource/roboto/400.css";
import ContactUs from "./Components/ContactUs";
import AboutUs from "./Components/AboutUs";
import Footer from "./Components/Footer";
import Portfolio from "./Components/Portfolio";
import "@fontsource/roboto/400.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Components/HomePage";
import Services from "./Components/Services";
import Amplify from "@aws-amplify/core";
import config from "../src/aws-exports";
import { createStandaloneToast } from "@chakra-ui/toast";
Amplify.configure(config);

const { ToastContainer, toast } = createStandaloneToast();

function App() {
  useEffect(() => {
    window.gtag("config", "AW-696694130");
  }, []);
  return (
    <div>
      <ToastContainer />
      <Router>
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <MenuBar />
          <main style={{ flexGrow: 1 }}>
            <AppRoutes />
          </main>
          <Footer />
        </div>
      </Router>
    </div>
  );
}
function AppRoutes() {
  //set all possible routes that the user may navigate to
  return (
    <Routes>
      <Route path="*" element={<HomePage />}></Route>
      <Route path="/estimate" element={<ContactUs />}></Route>
      <Route path="/estimate/" element={<ContactUs />}></Route>
      <Route path="/about" element={<AboutUs />}></Route>
      <Route path="/about/" element={<AboutUs />}></Route>
      <Route path="/portfolio" element={<Portfolio />}></Route>
      <Route path="/portfolio/" element={<Portfolio />}></Route>
      <Route path="/services" element={<Services />}></Route>
      <Route path="/services/" element={<Services />}></Route>
    </Routes>
  );
}
export default App;
