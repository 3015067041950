import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Image } from "mui-image";
import Image1 from "../static/Page_1.jpg";
import Image2 from "../static/Page_2.jpg";
function AboutUs() {
  return (
    <div>
      <Container className="rootAbout" sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Box
          className="content"
          sx={{
            margin: "0 auto",
            paddingTop: 2
          }}
        >
          <Typography
            variant="h4"
            sx={{ marginBottom: 2, textAlign: "center" }}
            gutterBottom
          >
            About Us
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Since the creation in 1985 Polonia
            Construction, has been New Jersey&#39;s premiere source for custom
            masonry and concrete work. Both residential and commercial. Now,
            under updated name, Polania Construction continues with heritage and
            strive for perfection. Started by partners Paul Banasik and Jack
            Marchwinski in 1985, in 2021 started undergoing generational
            handover into the shoulders of younger partners, Adrian Wasilewski
            and Jerzy Murdzek.
            <br />
            <br />
            &nbsp;&nbsp;Polania&#39;s skilled craftsmanship continually provides
            the highest quality specialty work with practical creativity and
            extraordinary customer service. Polania Construction, offers a wide
            variety of masonry services, including stone work, brick work, paver
            and concrete patio installation, retaining walls, drainage and many
            other. The skilled craftsmen utilize an array of specialized
            techniques and variety of materials to ensure that our completed
            projects enhance the building&#39;s architecture and exceed the
            owners&#39; expectations.
          </Typography>
        </Box>
      </Container>
      <Grid
        container
        spacing={2}
        sx={{
          borderRadius: "1%",
          background: "#24344d",
          display: "flex",
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: { md: 2 },
            "@media (max-width:599px)": {
              // styles for screens with a maximum width of 400px
              marginBottom: -4
            }
          }}
          sm={4}
        >
          <Image
            src={Image1}
            style={{
              width: "100%",
              height: "85%",
              borderRadius: "2%",
              maxWidth: "500px",
              maxHeight: "500px"
            }}
          />
          <Typography
            xs={12}
            sx={{ color: "#F5F5F4", paddingRight: 2, paddingLeft: 2 }}
            variant="subtitle2"
            align="center"
          >
            Polonia Construction's Legacy Continues with Generational Handover,
            Jerzy Pictured Here in 2000
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginBottom: 2
          }}
          sm={4}
        >
          <Image
            src={Image2}
            style={{
              width: "100%",
              height: "85%",
              borderRadius: "2%",
              maxWidth: "500px",
              maxHeight: "500px"
            }}
          />
          <Typography
            xs={12}
            sx={{ color: "#F5F5F4", paddingRight: 2, paddingLeft: 2 }}
            variant="subtitle2"
            align="center"
          >
            Jerzy, Ensuring Quality and Precision During Paver Installation for
            Over 20 years
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutUs;
