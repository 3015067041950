import React, { useState, useEffect } from "react";
import "./ImageList.css";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ImageList = ({ data }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(4);
  const [itemsPerRow, setItemsPerRow] = useState(4);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [viewType, setViewType] = useState(null);
  const handleNext = () => {
    setStartIndex(startIndex + itemsPerRow);
    setEndIndex(endIndex + itemsPerRow);
  };

  const handlePrev = () => {
    setStartIndex(startIndex - itemsPerRow);
    setEndIndex(endIndex - itemsPerRow);
  };
  let imageMobile = (
    <div className="scrolling-wrapper-flexbox">
      {data &&
        data.length > 0 &&
        data.slice(startIndex, endIndex).map((item, index) => (
          <div className="card" key={index}>
            <img
              src={item.url}
              alt={item.title}
              style={{ width: "300px", height: "300px", objectFit: "cover" }}
              onClick={() => handleZoomImage(item)}
            />
          </div>
        ))}
    </div>
  );
  let imageDesktop = (
    <div className="image-list">
      <div className="button-container">
        <Button
          className="button"
          onClick={handlePrev}
          disabled={startIndex === 0}
        >
          <ArrowBackIosIcon />
        </Button>
      </div>
      {data &&
        data.length > 0 &&
        data.slice(startIndex, endIndex).map((item, index) => (
          <div className="image-item" key={index}>
            <img
              src={item.url}
              alt={item.title}
              style={{ width: 300, height: 300, objectFit: "cover" }}
              onClick={() => handleZoomImage(item)}
            />
          </div>
        ))}
      <div className="button-container">
        <Button
          className="button"
          onClick={handleNext}
          disabled={endIndex >= data.length}
        >
          <ArrowForwardIosIcon />
        </Button>
      </div>
    </div>
  );
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let newItemsPerRow = 3;
      setViewType(imageDesktop);
      if (screenWidth < 725) {
        newItemsPerRow = data.length;
        setViewType(imageMobile);
      } else if (screenWidth < 1050) {
        newItemsPerRow = 2;
      } else if (screenWidth < 1350) {
        newItemsPerRow = 3;
      }

      const newEndIndex = startIndex + newItemsPerRow;
      if (newItemsPerRow !== itemsPerRow || newEndIndex !== endIndex) {
        setItemsPerRow(newItemsPerRow);
        setEndIndex(newEndIndex);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [startIndex, itemsPerRow, endIndex]);

  const handleZoomImage = item => {
    setZoomedImage(item);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  useEffect(() => {
    const handleClickOutside = e => {
      if (zoomedImage && !e.target.closest(".zoom-content")) {
        handleCloseZoom();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [zoomedImage]);

  return (
    <div className="image-list-container">
      {viewType}
      {zoomedImage && (
        <div className="zoom-box">
          <div className="zoom-content">
            <img src={zoomedImage.url} alt={zoomedImage.title} />
            <Button className="close-button" onClick={handleCloseZoom}>
              <CloseIcon />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageList;
