import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#E4F0FF",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  ...theme.typography.body2,
  padding: 20,
  color: "black",
  margin: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  maxWidth: "500px"
}));
function SeeMore() {
  return (
    <Container
      maxWidth={"1"}
      sx={{
        background: "#24344d",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        paddingBottom: 4,
        width: "100%"
      }}
    >
      <Item>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", fontWeight: "bold", marginBottom: 2 }}
        >
          Explore Our Portfolio
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", marginBottom: 4 }}
        >
          At Polania Construction, we take pride in our ability to bring our
          clients' vision to life. Our portfolio showcases our range of skills
          and experience, from intricate stone work to sleek and modern concrete
          designs. Browse our portfolio to see the breadth and quality of our
          work, and let us inspire your next project. Click the button below to
          start exploring.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/portfolio"
          sx={{
            width: "fit-content"
          }}
        >
          View Portfolio
        </Button>
      </Item>
    </Container>
  );
}

export default SeeMore;
