import React, { useState, useEffect } from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import Image1 from "../static/trucks.jpg";
import Image2 from "../static/paver1.jpeg";
import Image3 from "../static/paver2.jpeg";
export default function SlideShow() {
  const [height, setHeight] = useState(600);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setHeight(300);
      } else {
        setHeight(600);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MDBCarousel showIndicators fade>
      <MDBCarouselItem
        className="w-100 d-block"
        style={{ height: `${height}px`, width: "100%", objectFit: "cover" }}
        itemId={1}
        src={Image1}
        alt="..."
      ></MDBCarouselItem>

      <MDBCarouselItem
        className="w-100 d-block"
        itemId={2}
        style={{ height: `${height}px`, width: "100%", objectFit: "cover" }}
        src={Image2}
        alt="..."
      ></MDBCarouselItem>

      <MDBCarouselItem
        className="w-100 d-block"
        itemId={3}
        style={{ height: `${height}px`, width: "100%", objectFit: "cover" }}
        src={Image3}
        alt="..."
      ></MDBCarouselItem>
    </MDBCarousel>
  );
}
