import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Image } from "mui-image";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";

//individual unit control
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#E4F0FF",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  ...theme.typography.body2,
  padding: 20,
  color: "black",
  margin: 15,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexDirection: "column"
}));

export default function BoxWithText(props) {
  const imageArr = props.imageArr;
  const title = props.titleArr[0];
  const mainText = props.textArr;
  var opposite = props.opposite;
  const isMobile = useMediaQuery("(max-width:700px)");

  // Set distance between text and image components
  const distance = isMobile ? 5 : 15;

  let imageFunc = imageArr.map(img => {
    return (
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        key={img}
        sm={6}
      >
        <Image
          src={img}
          style={{
            width: "100%",
            height: "85%",
            borderRadius: "2%",
            maxWidth: "600px",
            maxHeight: "600px"
            // Set the margin bottom to the distance variable
          }}
        />
      </Grid>
    );
  });

  let textFunc = mainText.map(text => {
    return (
      <Grid
        item
        key={text}
        sm={6}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Item
          style={{
            height: "fit-content"
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 8
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{ whiteSpace: "pre-wrap" }}
            variant="body1"
            gutterBottom
          >
            {text}
          </Typography>
        </Item>
      </Grid>
    );
  });

  return (
    //whole unit control
    <Grid
      container
      sx={{
        background: "#24344d",
        padding: 2,
        marginBottom: 0,
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
      }}
    >
      {isMobile ? (
        <>
          {imageFunc}
          {textFunc}
        </>
      ) : (
        <>
          {opposite === 1 ? textFunc : imageFunc}
          {opposite === 1 ? imageFunc : textFunc}
        </>
      )}
    </Grid>
  );
}
