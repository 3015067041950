import React, { useState } from "react";
import { Grid, TextField, Button, Container, Typography } from "@mui/material";
import { API } from "aws-amplify";
import { createCandidate } from "../graphql/mutations";
import { useToast } from "@chakra-ui/react";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "AW-696694130"
};

TagManager.initialize(tagManagerArgs);
export default function ContactForm() {
  const toast = useToast();
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    projectDescription: "",
    phoneNumber: ""
  });
  const handleContactFormSubmit = async e => {
    e.preventDefault();
    const {
      name,
      email,
      address,
      city,
      zipCode,
      phoneNumber,
      projectDescription
    } = formState;
    if (
      name &&
      email &&
      address &&
      city &&
      zipCode &&
      projectDescription &&
      phoneNumber
    ) {
      try {
        await API.graphql({
          query: createCandidate,
          variables: {
            input: {
              name,
              email,
              address,
              city,
              zipCode,
              projectDescription,
              phoneNumber
            }
          }
        });
        setFormState({
          name: "",
          email: "",
          address: "",
          city: "",
          zipCode: "",
          projectDescription: "",
          phoneNumber: ""
        });
        toast({
          title: "Thank you for submitting an inquiry!",
          position: "top-right",
          description: "We'll get in touch with you within 1-2 days",
          status: "success",
          duration: 5000,
          isClosable: true
        });
        window.gtag("event", "conversion", {
          send_to: "AW-696694130/qEDyCK-1i44YEPLqmswC"
        });
      } catch (e) {
        console.log(e);
        toast({
          title: "Error",
          position: "top-right",
          description: e.message,
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: "Uh-Oh 😥",
        position: "top-right",
        description: "Please verify all fields are filled out.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <Container sx={{ flexGrow: 1, marginBottom: 4 }}>
      <Typography
        variant="h4"
        sx={{ marginTop: 4, marginBottom: 4, textAlign: "center" }}
        gutterBottom
      >
        Schedule an Estimate
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 6, textAlign: "center" }}>
        Thank you for considering Polania Construction for your next project. To
        schedule a free estimate, simply fill out the form below with your
        contact information and project details. Alternatively, if you prefer to
        call us directly, we can be reached at either of these two phone
        numbers: (732)-205-0086 or (732)-525-8118. We will review your
        submission or answer your call and get in touch with you within 1-2
        business days to further discuss your project plans and schedule a
        convenient time for an on-site visit. During the visit, our team will
        assess the project requirements, answer any questions you may have, and
        provide you with an accurate estimate for the cost and timeline of the
        project. We take pride in our craftsmanship and customer satisfaction,
        and we look forward to helping you bring your vision to life.
      </Typography>
      <form onSubmit={handleContactFormSubmit} style={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              spacing={2}
              fullWidth
              required
              name="contact_name"
              value={formState.name}
              onChange={e =>
                setFormState({ ...formState, name: e.target.value })
              }
              label="Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              spacing={2}
              value={formState.address}
              onChange={e =>
                setFormState({ ...formState, address: e.target.value })
              }
              fullWidth
              required
              label="Street Address"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              spacing={2}
              value={formState.city}
              onChange={e =>
                setFormState({ ...formState, city: e.target.value })
              }
              fullWidth
              required
              label="City"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              spacing={2}
              value={formState.zipCode}
              onChange={e =>
                setFormState({ ...formState, zipCode: e.target.value })
              }
              fullWidth
              required
              label="Zip Code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              spacing={2}
              value={formState.phoneNumber}
              onChange={e =>
                setFormState({ ...formState, phoneNumber: e.target.value })
              }
              fullWidth
              required
              label="Phone Number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={formState.email}
              type="email"
              onChange={e =>
                setFormState({ ...formState, email: e.target.value })
              }
              spacing={2}
              fullWidth
              required
              label="Email"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              sx={{ marginBottom: 2 }}
              fullWidth
              multiline
              rows={4}
              required
              onChange={e =>
                setFormState({
                  ...formState,
                  projectDescription: e.target.value
                })
              }
              value={formState.projectDescription}
              label="Project Description"
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center"
            }}
            xs={12}
          >
            <Button
              type="submit"
              name="submit_inquiry"
              sx={{
                marginTop: 2
              }}
              variant="contained"
              color="primary"
            >
              Submit Inquiry
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
