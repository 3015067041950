import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SlideShow from "./SlideShow";
import AboutUs from "./AboutUs";
import BoxWithText from "./BoxWithText";
import ScheduleEstimate from "./ScheduleEstimate";
import Services from "./Services";
import Slideshow from "./SlideShow";
const theme = createTheme(theme => ({
  typography: {
    allVariants: {
      textTransform: "none",
      fontFamily: "roboto"
    }
  },
  mode: "dark",
  primary: {
    main: "#5893df"
  },
  secondary: {
    main: "#2ec5d3"
  },
  background: {
    default: "#192231",
    paper: "#24344d"
  }
}));
export default function HomePage() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Slideshow />
        <AboutUs theme={theme} />
        <Services></Services>
        <ScheduleEstimate />
      </div>
    </ThemeProvider>
  );
}
