import React from "react";
import { Grid, TextField, Button, Container, Typography } from "@mui/material";

function Footer() {
  const licenseNumber = "#13VH12000800"; // Replace with the actual license number
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        marginTop: "auto",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "#24344d"
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          padding: 1
        }}
      >
        <Typography sx={{ color: "#F5F5F4", marginRight: 2 }}>
          License Number: {licenseNumber}
        </Typography>
        <Typography sx={{ color: "#F5F5F4" }}>
          &copy; {currentYear} Polania Construction. All Rights Reserved.{" "}
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
