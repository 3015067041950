import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";

function ScheduleEstimate() {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2 }}>
        Schedule a Free Estimate
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center", marginBottom: 4 }}>
        Get a no-obligation estimate for your next project. Our team will work
        with you to understand your needs and provide you with an accurate
        estimate.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/estimate"
      >
        Request an Estimate
      </Button>
    </Container>
  );
}

export default ScheduleEstimate;
